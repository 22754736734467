import axios from 'axios';
import cookies from './cookies';
import { toast } from 'react-toastify';
const instance = axios.create({
  baseURL: `https://${cookies.processenv.REACT_APP_ENV}.${cookies.processenv.REACT_APP_API}`,
});

instance.defaults.timeout = 60000;

//Passes constructor and function into instance, will be used to cancel requests from unmounted components.
instance.CancelToken = axios.CancelToken;
instance.isCancel = axios.isCancel;

// Add/configure any interceptors
instance.interceptors.request.use(
  (config) => {

    try {
      let platform = JSON.parse(localStorage.getItem('device_info'));
      let installed_app_info = JSON.parse(localStorage.getItem('installed_app_info'));
      config.headers['platform'] = platform?.platform || localStorage.getItem('platform').split(',')[0];
      config.headers['version'] = installed_app_info?.version || cookies?.app_version;
    } catch (error) {
      console.log('error', error);
    }

    const storedJWT = cookies.getJWT();
    if (storedJWT) {
      config.headers.common['Authorization'] = `Bearer ${storedJWT}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);



instance.interceptors.response.use(response => {
  // console.log('response', response);
  return response;
}, error => {

if(1){
  try {

    // console.log('error', error);
    // console.log('error.config.url', error.config.url);

    if (error.toString().match(/Error: timeout of \d+ms exceeded/)) {
      toast.error("Request timeout!", {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }

    if (error.config.url !== '/weeklyLoanSummary' &&  error.config.url !== '/behaviourActivity' && error.config.url !== '/users/behaviourActivity' && error.config.url !== '/authenticate' && error.config.url !== '/visitor/create' && error.config.url !== '/applicants') {
      // console.log(error);
      let errorText = "Something went wrong!"
      if (!error.response) {
        errorText = ("Session-Time-Out or Network-Error!");
      }
      else if (error.response.status === 401) {
        errorText = ("Session timeout, Login again.");
      } else if (error.response.status === 500) {
        // errorText = ("Internal server error.");
        errorText = ("Session timeout!");
      } else if (error.response.status === 404) {
        // errorText = ("Internal server error.");
        errorText = ("Session timeout!");
      }
      try {
        toast.dismiss();
        toast.error(errorText, {
          position: toast.POSITION.TOP_RIGHT
        });

      } catch (error) {
        console.log('error', error);
      }

      // toast.error(errorText, {
      //   position: toast.POSITION.TOP_RIGHT
      // });

      localStorage.clear();
      setTimeout(() => {
        // try {
        //      toast.error("Dev env, Not Terminating! ", {
        //     position: toast.POSITION.TOP_RIGHT
        //   });

        // } catch (error) {
        //   console.log('error', error);
        // }
        window.location.href = "/";


        // backendLogout()
        //   .then(({ data }) => {
        //     window.location.href = "/auth/login";
        //   })
        //   .catch(() => {
        //     window.location.href = "/auth/login";
        //   });
      }, 1500);
    }


  } catch (error) {
    console.log(error)
  }
}


  return error;
})

// instance.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   (error) => {
//     if (error.response && error.response.data) {
//       // Sentry.captureException({
//       //   data: error.response.config.data,
//       //   error: error.response.data,
//       //   user: cookies.getUserID(),
//       // });
//       return Promise.reject(error.response.data);
//     } else if (error.message) {
//       Sentry.captureException({
//         error: error.message,
//         user: cookies.getUserID(),
//       });
//     }
//     return Promise.reject(error.message);
//   }
// );

export default instance;
